@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
@include foundation-typography;

// @include foundation-xy-grid-classes;
// @include foundation-flex-classes;
// @include foundation-grid;
// @include foundation-flex-grid;
// @include foundation-button;
// @include foundation-forms;
// @include foundation-range-input;
// @include foundation-accordion;
// @include foundation-accordion-menu;
// @include foundation-badge;
// @include foundation-breadcrumbs;
// @include foundation-button-group;
// @include foundation-callout;
// @include foundation-card;
// @include foundation-close-button;
// @include foundation-menu;
// @include foundation-menu-icon;
// @include foundation-drilldown-menu;
// @include foundation-dropdown;
// @include foundation-dropdown-menu;
// @include foundation-responsive-embed;
// @include foundation-label;
// @include foundation-media-object;
// @include foundation-off-canvas;
// @include foundation-orbit;
// @include foundation-pagination;
// @include foundation-progress-bar;
// @include foundation-slider;
// @include foundation-sticky;
// @include foundation-reveal;
// @include foundation-switch;
// @include foundation-table;
// @include foundation-tabs;
// @include foundation-thumbnail;
// @include foundation-title-bar;
// @include foundation-tooltip;
// @include foundation-top-bar;
// @include foundation-visibility-classes;
// @include foundation-float-classes;

// @include foundation-prototype-classes;

// @include motion-ui-transitions;
// @include motion-ui-animations;

$hero-full-screen-bg: url('/assets/img/night-sky-bg.jpg') center center no-repeat;
$hero-full-screen-bg-size: cover;
$hero-full-screen-text-color: $white;

.hero-full-screen {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: $hero-full-screen-bg;
  background-size: $hero-full-screen-bg-size;

  .top-content-section {
  }

  .middle-content-section {
    text-align: center;
    color: $hero-full-screen-text-color;
  }

  .bottom-content-section {
    text-align: center;
    color: $hero-full-screen-text-color;
  }
}

.logo {
  margin: 1rem 0 0 0;
  width: 75%;
  max-width: 600px;

}

.services {
  margin: 3rem 0;
  h2 {
    color: $primary-color;
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .5rem;
    @include breakpoint(medium) {
      font-size: 1.5rem;
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  li {
    margin: 0;
    padding: 0 .25em 0 0;
    font-size: 1rem;
    display: inline-block;
    @include breakpoint(medium) {
      font-size: 1.25rem;
    }
    + li::before {
      content: "•";
      padding-right: .5em;
    }
  }
}

.contact-link {
  margin: 1rem 0 4rem 0;
  font-size: 1rem;
  @include breakpoint(medium) {
    font-size: 1.25rem;
  }
  a {
    display: inline-block;
    padding-bottom: .0625em;
    border-bottom: 1px solid transparent;
    &:hover, &:active {
      border-bottom-color: $primary-color;
    }
  }
}
